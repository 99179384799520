/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 ~ Copyright 2020 Adobe Systems Incorporated
 ~
 ~ Licensed under the Apache License, Version 2.0 (the "License");
 ~ you may not use this file except in compliance with the License.
 ~ You may obtain a copy of the License at
 ~
 ~     http://www.apache.org/licenses/LICENSE-2.0
 ~
 ~ Unless required by applicable law or agreed to in writing, software
 ~ distributed under the License is distributed on an "AS IS" BASIS,
 ~ WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 ~ See the License for the specific language governing permissions and
 ~ limitations under the License.
 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

import React from 'react'
import { EditableComponent } from '@adobe/aem-react-editable-components'
import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager'
import Container from '../../../templates/Container/Container'
import SlideEditConfig from './SlideEditConfig'
import { SlideModelProps } from './types'
import { EditableComponentPropsType } from '../../../../utils/prop-types'
import withComponentRefresh from '../../../../utils/aem/editor/withComponentRefresh'

// slide placeholder
const Slide: React.FC<EditableComponentPropsType<SlideModelProps>> = withComponentRefresh(({
  ...props
}) => {
  const { id, gtm, ...rest } = props.model
  const editableProps = { ...props, model: { ...rest } }

  return (
    <EditableComponent {...editableProps}>
      {AuthoringUtils.isInEditor() && (
        <>
          <Container {...props} />
          <span>{SlideEditConfig.emptyLabel}</span>
        </>
      )}
    </EditableComponent>
  )
})

export default Slide
