import React from 'react'
import { EditableComponent } from '@adobe/aem-react-editable-components'
import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager'
import {
  ThemeUtils,
  getColumnWidth,
  ContainerDisplays,
  ContainerFlexGaps,
  ContainerPaddings,
  ContainerFlexDirections,
  Container as UIFoundationsContainer,
  Slider
} from '@dcxwam/dcx-wam-ui-foundations'
import { slidesArray } from '../Slides/Slides'
import { canUseDom } from '../../../../utils/isSsr'
import Container from '../../Container/Container'
import { EditableComponentPropsType } from '../../../../utils/prop-types'
import { SlidesArrayProps } from '../types'
import withComponentRefresh from '../../../../utils/aem/editor/withComponentRefresh'
import { NoSsr } from '../../../functional/NoSsr/NoSsr'

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
canUseDom && import('@dcxwam/dcx-wam-ui-foundations/css')

interface SliderGridLayoutProps {
  gridItems: Array<React.ReactNode>
  index: number
}

interface SliderDesktopGridProps {
  items: React.JSX.Element[]
  background: string
}

export interface SliderVariantEProps extends Partial<SlidesArrayProps> {
  accessibilityFirst: string
  accessibilityLast: string
  accessibilityNext: string
  accessibilityPrevious: string
  id: string
  background: string
  adaptiveHeight: boolean
}

const SliderGridLayout = ({ gridItems, index }: SliderGridLayoutProps) => {
  const firstColumn = gridItems.slice(0, 2)
  const secondColumn = gridItems.slice(2, gridItems.length)

  const configContainerWrapper = {
    display: ContainerDisplays.Flex,
    flexGap: ContainerFlexGaps.Size_3,
    // fallback padding in case there is multiple grids
    ...(index > 0 && {
      padding: ThemeUtils.getResponsiveProp([{ t: ContainerPaddings.Size_3 }])
    })
  }

  const configContainerColumn = {
    width: '50%',
    display: ContainerDisplays.Flex,
    flexDirection: ContainerFlexDirections.Column,
    flexGap: ContainerFlexGaps.Size_3
  }

  return (
    <UIFoundationsContainer {...configContainerWrapper}>
      <UIFoundationsContainer {...configContainerColumn}>
        {firstColumn.map((element: React.ReactNode) => element)}
      </UIFoundationsContainer>
      <UIFoundationsContainer {...configContainerColumn}>
        {secondColumn.map((element: React.ReactNode) => element)}
      </UIFoundationsContainer>
    </UIFoundationsContainer>
  )
}

const SliderDesktopGrid = ({
  items = [],
  background
}: SliderDesktopGridProps) => {
  const chunks = []
  for (let i = 0; i < items.length; i += 4) {
    chunks.push(items.slice(i, i + 4))
  }

  return (
    <UIFoundationsContainer background={background}>
      {chunks.map((grid: Array<React.ReactNode>, i: number) => (
        <SliderGridLayout gridItems={grid} index={i} key={i} />
      ))}
    </UIFoundationsContainer>
  )
}

const SliderVariantE: React.FC<
  EditableComponentPropsType<SliderVariantEProps>
> = withComponentRefresh(({ ...props }) => {
  const {
    accessibilityFirst,
    accessibilityLast,
    accessibilityNext,
    accessibilityPrevious,
    id,
    background,
    adaptiveHeight,
    ...rest
  } = props.model
  const editableProps = { ...props, model: { ...rest } }

  const slides = slidesArray({
    ...(props as unknown as SlidesArrayProps)
  })
  const slidesDesktopGrid = slidesArray({
    ...(props as unknown as SlidesArrayProps),
    height: 'auto'
  })
  const configSliderE = {
    className: 'slider-e',
    background,
    centeredSlides: true,
    slides,
    lazyLoading: true,
    keyboard: true,
    autoHeight: adaptiveHeight,
    verticalPadding: false,
    numberOfColumns: ThemeUtils.getResponsiveProp([getColumnWidth(12, 12)]),
    loop: false,
    sliderPadding: ThemeUtils.getResponsiveProp([
      { x: ContainerPaddings.Size_4 }
    ]),
    propsPerBreakpoints: ThemeUtils.getResponsiveProp([
      {
        slidesPerView: 1,
        spaceBetween: Number(ThemeUtils.getComputedSpacing(Number(3), true))
      }
    ])
  }

  return (
    <NoSsr>
      <EditableComponent {...editableProps}>
        {AuthoringUtils.isInEditor() ? (
          <Container {...props} />
        ) : (
          ThemeUtils.getResponsiveRender([
            <Slider {...configSliderE} />,
            null,
            <SliderDesktopGrid
              items={slidesDesktopGrid}
              background={background}
            />
          ])
        )}
      </EditableComponent>
    </NoSsr>
  )
})

export default SliderVariantE
