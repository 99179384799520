import React from 'react'
import Container from '../../Container/Container'
import { KeyValueObject } from '../../../atoms/Accordion/types'
import { SlidesProps, SlidesArrayProps } from '../types'
import withComponentRefresh from '../../../../utils/aem/editor/withComponentRefresh'

const Slide: React.FC<SlidesProps> = withComponentRefresh(({ ...props }) => {
  const { model, gtmItems, height } = props
  const dataAttrs = {}
  gtmItems?.forEach((gtmItem: KeyValueObject) => {
    if (gtmItem) {
      dataAttrs[gtmItem.key] = gtmItem.value
    }
  })

  const containerConfig = {
    model: {
      ...model,
      ...dataAttrs,
      ...(height ? { height } : { height: 'full' }),
      display: 'flex'
    }
  }

  return <Container {...containerConfig} />
})

export const slidesArray: React.FC<SlidesArrayProps> = ({ ...props }) => {
  if (
    !props ||
    !props.model ||
    !props.model.cqItems ||
    props.model.cqItems.length === 0
  ) {
    return []
  }
  const { height } = props
  const slides = Object.keys(props?.model?.cqItems)?.map((cqItemKey) => {
    const {
      ':items': cqItems,
      ':itemsOrder': cqItemsOrder,
      ':type': cqType,
      gtmItems,
      ...itemRest
    } = props.model.cqItems[cqItemKey]

    const slideConfig = {
      gtmItems,
      height,
      model: {
        cqItems,
        cqItemsOrder,
        cqType,
        ...itemRest
      }
    }

    return <Slide {...slideConfig} key={cqItemKey} />
  })

  return slides
}
