import React from 'react'
import { EditableComponent } from '@adobe/aem-react-editable-components'
import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager'
import {
  Slider,
  SliderThemes,
  ThemeUtils,
  getColumnWidth,
  ContainerPaddings,
  SliderNavigationPositions
} from '@dcxwam/dcx-wam-ui-foundations'
import { slidesArray } from '../Slides/Slides'
import { canUseDom } from '../../../../utils/isSsr'
import Container from '../../Container/Container'
import { EditableComponentPropsType } from '../../../../utils/prop-types'
import { SliderVariantProps } from '../types'
import withComponentRefresh from '../../../../utils/aem/editor/withComponentRefresh'
import { NoSsr } from '../../../functional/NoSsr/NoSsr'

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
canUseDom && import('@dcxwam/dcx-wam-ui-foundations/css')

const SliderVariantA: React.FC<EditableComponentPropsType<SliderVariantProps>> =
  withComponentRefresh(({ ...props }) => {
    const {
      accessibilityFirst,
      accessibilityLast,
      accessibilityNext,
      accessibilityPrevious,
      id,
      background,
      adaptiveHeight,
      theme,
      ...rest
    } = props.model
    const editableProps = { ...props, model: { ...rest } }

    const slides = slidesArray({ ...props })

    let sliderATheme = SliderThemes.Light
    switch (theme) {
      case 'light':
        sliderATheme = SliderThemes.Light
        break
      case 'dark':
        sliderATheme = SliderThemes.Dark
        break
      default:
        break
    }

    const configSliderA = {
      className: 'slider-a',
      navigationPosition: SliderNavigationPositions.Mid,
      slideToClickedSlide: true,
      background,
      centeredSlides: true,
      slides,
      sliderTheme: sliderATheme,
      lazyLoading: true,
      keyboard: true,
      autoHeight: adaptiveHeight,
      numberOfColumns: ThemeUtils.getResponsiveProp([
        getColumnWidth(10, 12),
        getColumnWidth(10, 12),
        getColumnWidth(8, 12)
      ]),
      propsPerBreakpoints: ThemeUtils.getResponsiveProp([
        {
          slidesPerView: 1,
          spaceBetween: Number(ThemeUtils.getComputedSpacing(Number(3), true))
        },
        {
          slidesPerView: 1,
          spaceBetween: Number(ThemeUtils.getComputedSpacing(Number(3), true))
        },
        {
          slidesPerView: 1,
          spaceBetween: Number(ThemeUtils.getComputedSpacing(Number(5), true))
        }
      ]),
      sliderPadding: ThemeUtils.getResponsiveProp([
        { l: ContainerPaddings.Size_0 }
      ]),
      accessibilityProps: {
        firstSlideMessage: accessibilityFirst,
        lastSlideMessage: accessibilityLast,
        prevSlideMessage: accessibilityPrevious,
        nextSlideMessage: accessibilityNext
      }
    }

    return (
      <NoSsr>
        <EditableComponent {...editableProps}>
          {AuthoringUtils.isInEditor() ? (
            <Container {...props} />
          ) : (
            <Slider {...configSliderA} />
          )}
        </EditableComponent>
      </NoSsr>
    )
  })

export default SliderVariantA
