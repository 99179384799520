import React from 'react'
import { EditableComponent } from '@adobe/aem-react-editable-components'
import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager'
import {
  Slider,
  SliderThemes,
  ThemeUtils,
  getColumnWidth,
  ContainerPaddings,
  SliderNavigationPositions,
  SliderNavigationButtons,
  TabsVariants,
  ContainerWidths,
  TextHorizontalAlignments as TabsHorizontalAlignments
} from '@dcxwam/dcx-wam-ui-foundations'
import { canUseDom } from '../../../../utils/isSsr'
import Container from '../../Container/Container'
import { SlidesProps, SliderVariantDProps } from '../types'
import { EditableComponentPropsType } from '../../../../utils/prop-types'
import withComponentRefresh from '../../../../utils/aem/editor/withComponentRefresh'
import { NoSsr } from '../../../functional/NoSsr/NoSsr'

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
canUseDom && import('@dcxwam/dcx-wam-ui-foundations/css')

const Slide: React.FC<SlidesProps> = withComponentRefresh(({ ...props }) => {
  const { model, gtmItems } = props
  const dataAttrs = {}
  gtmItems?.forEach((gtmItem) => {
    if (gtmItem) {
      // @ts-expect-error String as object iteral
      dataAttrs[gtmItem.key] = gtmItem.value
    }
  })
  const containerConfig = {
    model: {
      ...model,
      ...dataAttrs
    }
  }

  return <Container {...containerConfig} />
})

const SliderVariantD: React.FC<
  EditableComponentPropsType<SliderVariantDProps>
> = withComponentRefresh(({ ...props }) => {
  const {
    accessibilityFirst,
    accessibilityLast,
    accessibilityNext,
    accessibilityPrevious,
    id,
    background,
    adaptiveHeight,
    theme,
    tabsAriaLabel,
    tabControls,
    ...rest
  } = props.model
  const editableProps = { ...props, model: { ...rest } }

  // @ts-expect-error AEM Model is undefined
  const slides = Object.keys(props?.model?.cqItems)?.map((cqItemKey) => {
    const {
      ':items': cqItems,
      ':itemsOrder': cqItemsOrder,
      ':type': cqType,
      // @ts-expect-error AEM Model does not have the property
      gtmItems,
      // @ts-expect-error AEM Model does not have the property
      tabLabel,
      // @ts-expect-error AEM Model does not have the property
      theme: slideTheme,
      ...itemRest
      // @ts-expect-error AEM Model is undefined
    } = props.model.cqItems[cqItemKey]

    let slideDTheme = SliderThemes.Light
    switch (slideTheme) {
      case 'light':
        slideDTheme = SliderThemes.Light
        break
      case 'dark':
        slideDTheme = SliderThemes.Dark
        break
      default:
        break
    }
    const slideConfig = {
      gtmItems,
      model: {
        cqItems,
        cqItemsOrder,
        cqType,
        ...itemRest
      }
    }

    return {
      slide: <Slide {...slideConfig} />,
      slideTheme: slideDTheme,
      slideTabLabel: tabLabel
    }
  })

  let sliderDTheme = SliderThemes.Light
  switch (theme) {
    case 'light':
      sliderDTheme = SliderThemes.Light
      break
    case 'dark':
      sliderDTheme = SliderThemes.Dark
      break
    default:
      break
  }

  const configSliderD = {
    className: 'slider-d',
    navigationPosition: SliderNavigationPositions.Mid,
    navigationConfig: {
      buttons: SliderNavigationButtons.Transparent,
      width: ThemeUtils.getResponsiveProp([getColumnWidth(12, 12)])
    },
    background,
    lazyLoading: true,
    keyboard: true,
    centeredSlides: true,
    slideToClickedSlide: true,
    sliderPagination: false,
    autoHeight: adaptiveHeight,
    sliderWidths: ContainerWidths.Full,
    sliderTheme: sliderDTheme,
    horizontalAlignment: TabsHorizontalAlignments.Center,
    numberOfColumns: ThemeUtils.getResponsiveProp([getColumnWidth(12, 12)]),
    propsPerBreakpoints: ThemeUtils.getResponsiveProp([
      {
        slidesPerView: 1
      }
    ]),
    sliderPadding: ThemeUtils.getResponsiveProp([
      { l: ContainerPaddings.Size_0 }
    ]),
    ...(tabControls && {
      sliderTabs: {
        tabsVariant: TabsVariants.Scrollable,
        'aria-label': tabsAriaLabel
      }
    }),
    slides,
    accessibilityProps: {
      firstSlideMessage: accessibilityFirst,
      lastSlideMessage: accessibilityLast,
      prevSlideMessage: accessibilityPrevious,
      nextSlideMessage: accessibilityNext
    }
  }

  return (
    <NoSsr>
      <EditableComponent {...editableProps}>
        {AuthoringUtils.isInEditor() ? (
          <Container {...props} />
        ) : (
          <Slider {...configSliderD} />
        )}
      </EditableComponent>
    </NoSsr>
  )
})

export default SliderVariantD
