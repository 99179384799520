/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 ~ Copyright 2020 Adobe Systems Incorporated
 ~
 ~ Licensed under the Apache License, Version 2.0 (the "License");
 ~ you may not use this file except in compliance with the License.
 ~ You may obtain a copy of the License at
 ~
 ~     http://www.apache.org/licenses/LICENSE-2.0
 ~
 ~ Unless required by applicable law or agreed to in writing, software
 ~ distributed under the License is distributed on an "AS IS" BASIS,
 ~ WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 ~ See the License for the specific language governing permissions and
 ~ limitations under the License.
 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

import React from 'react'
import { EditableComponent } from '@adobe/aem-react-editable-components'
import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager'
import {
  Slider,
  getColumnWidth,
  SliderSpecificSlidesPerViewProps,
  SliderNavigationPositions,
  ThemeUtils
} from '@dcxwam/dcx-wam-ui-foundations'
import { canUseDom } from '../../../../utils/isSsr'
import Container from '../../Container/Container'
import { SliderVariantCProps, SlidesProps } from '../types'
import { EditableComponentPropsType } from '../../../../utils/prop-types'
import withComponentRefresh from '../../../../utils/aem/editor/withComponentRefresh'
import { NoSsr } from '../../../functional/NoSsr/NoSsr'

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
canUseDom && import('@dcxwam/dcx-wam-ui-foundations/css')

const Slide: React.FC<SlidesProps> = withComponentRefresh(({ ...props }) => {
  const { model, gtmItems } = props
  const dataAttrs = {}
  gtmItems?.forEach((gtmItem) => {
    if (gtmItem) {
      // @ts-expect-error String as object iteral
      dataAttrs[gtmItem.key] = gtmItem.value
    }
  })
  const containerConfig = {
    model: {
      ...model,
      ...dataAttrs
    }
  }

  return <Container {...containerConfig} />
})

const SliderVariantC: React.FC<
  EditableComponentPropsType<SliderVariantCProps>
> = withComponentRefresh(({ ...props }) => {
  if (
    !props ||
    !props.model ||
    !props.model.cqItems ||
    // @ts-expect-error Check for element
    props.model.cqItems.length === 0
  ) {
    return null
  }
  const {
    id,
    background,
    theme,
    accessibilityFirst,
    accessibilityLast,
    accessibilityNext,
    accessibilityPrevious,
    adaptiveHeight,
    navigationArrowsInMobile,
    ...rest
  } = props.model
  const editableProps = { ...props, model: { ...rest } }

  const slides = Object.keys(props?.model?.cqItems)?.map((cqItemKey) => {
    const {
      ':items': cqItems,
      ':itemsOrder': cqItemsOrder,
      ':type': cqType,
      // @ts-expect-error AEM Model does not have the property
      gtmItems,
      ...itemRest
      // @ts-expect-error AEM Model possibly undefined
    } = props.model.cqItems[cqItemKey]
    // @ts-expect-error AEM Model does not have the property
    const { 'inactive-image': inactiveImage, ...restCqItems } = cqItems
    const slideConfigActive = {
      gtmItems,
      model: {
        cqItems: restCqItems,
        cqItemsOrder,
        cqType,
        ...itemRest
      }
    }
    // @ts-expect-error AEM Model does not have the property
    const { 'active-image': activeImage, container, ...restICqItems } = cqItems
    const slideConfigInactive = {
      gtmItems,
      model: {
        cqItems: restICqItems,
        cqItemsOrder,
        cqType,
        ...itemRest
      }
    }

    return {
      inactiveSlide: <Slide {...slideConfigInactive} />,
      activeSlide: <Slide {...slideConfigActive} />
    }
  })

  const configSliderC = {
    className: 'slider-c',
    background,
    centeredSlides: true,
    slides,
    lazyLoading: true,
    keyboard: true,
    autoHeight: adaptiveHeight,
    accessibilityProps: {
      firstSlideMessage: accessibilityFirst,
      lastSlideMessage: accessibilityLast,
      prevSlideMessage: accessibilityPrevious,
      nextSlideMessage: accessibilityNext
    },
    numberOfColumns: ThemeUtils.getResponsiveProp([
      getColumnWidth(10, 12),
      getColumnWidth(12, 12)
    ]),
    loop: true,
    propsPerBreakpoints: ThemeUtils.getResponsiveProp([
      {
        slidesPerView: 1,
        spaceBetween: Number(ThemeUtils.getComputedSpacing(Number(3), true))
      },
      {
        slidesPerView: SliderSpecificSlidesPerViewProps.Auto,
        spaceBetween: Number(ThemeUtils.getComputedSpacing(Number(5), true))
      }
    ]),
    ...(navigationArrowsInMobile
      ? { navigationPosition: SliderNavigationPositions.Mid }
      : {})
  }

  return (
    <NoSsr>
      <EditableComponent {...editableProps}>
        {AuthoringUtils.isInEditor() ? (
          <Container {...props} />
        ) : (
          <Slider {...configSliderC} />
        )}
      </EditableComponent>
    </NoSsr>
  )
})

export default SliderVariantC
