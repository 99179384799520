import React from 'react'
import { EditableComponent } from '@adobe/aem-react-editable-components'
import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager'
import {
  Slider,
  SliderThemes,
  SliderNavigationPositions
} from '@dcxwam/dcx-wam-ui-foundations'
import { slidesArray } from '../Slides/Slides'
import { canUseDom } from '../../../../utils/isSsr'
import Container from '../../Container/Container'
import { EditableComponentPropsType } from '../../../../utils/prop-types'
import { SliderVariantProps } from '../types'
import withComponentRefresh from '../../../../utils/aem/editor/withComponentRefresh'
import { NoSsr } from '../../../functional/NoSsr/NoSsr'

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
canUseDom && import('@dcxwam/dcx-wam-ui-foundations/css')

const SliderVariantB: React.FC<EditableComponentPropsType<SliderVariantProps>> =
  withComponentRefresh(({ ...props }) => {
    const {
      id,
      background,
      theme,
      accessibilityFirst,
      accessibilityLast,
      accessibilityNext,
      accessibilityPrevious,
      adaptiveHeight,
      ...rest
    } = props.model
    const editableProps = { ...props, model: { ...rest } }

    const slides = slidesArray({ ...props })

    let sliderTheme = SliderThemes.Light
    switch (theme) {
      case 'light':
        sliderTheme = SliderThemes.Light
        break
      case 'dark':
        sliderTheme = SliderThemes.Dark
        break
      default:
        break
    }

    const configSliderB = {
      navigationPosition: SliderNavigationPositions.Top,
      background,
      centeredSlides: false,
      slides,
      sliderTheme,
      lazyLoading: true,
      keyboard: true,
      className: 'slider-b',
      autoHeight: adaptiveHeight,
      minHeightSlideWidth: true,
      accessibilityProps: {
        firstSlideMessage: accessibilityFirst,
        lastSlideMessage: accessibilityLast,
        prevSlideMessage: accessibilityPrevious,
        nextSlideMessage: accessibilityNext
      }
    }

    return (
      <NoSsr>
        <EditableComponent {...editableProps}>
          {AuthoringUtils.isInEditor() ? (
            <Container {...props} />
          ) : (
            <Slider {...configSliderB} />
          )}
        </EditableComponent>
      </NoSsr>
    )
  })

export default SliderVariantB
